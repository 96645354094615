$sm-mobile-breakpoint: 670px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$max-breakpoint: 1280px;

$dtu-blue: #b3e9fe;
$dtu-white: #fffdf7;
$dtu-black: #0f0f11;

$dtu-green: #35b0ab;
$dtu-purple: #634099;

//dtu-blue as rgba: rgba(179, 233, 254, 0.9);

@mixin bp-sm-mobile {
	@media only screen and (min-height: $sm-mobile-breakpoint) {
		@content;
	}
}

@mixin bp-tablet {
	@media only screen and (min-width: $tablet-breakpoint) {
		@content;
	}
}

@mixin bp-desktop {
	@media only screen and (min-width: $desktop-breakpoint) {
		@content;
	}
}

@mixin bp-max {
	@media only screen and (min-width: $max-breakpoint) {
		@content;
	}
}

@mixin flex-support {
	display: -webkit-box; 
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex; 
	display: flex;
}

@mixin inline-flex {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
	  -webkit-box-direction: reverse;
	  -webkit-box-orient: horizontal;
	  -moz-box-direction: reverse;
	  -moz-box-orient: horizontal;
	} @else if $value == column {
	  -webkit-box-direction: normal;
	  -webkit-box-orient: vertical;
	  -moz-box-direction: normal;
	  -moz-box-orient: vertical;
	} @else if $value == column-reverse {
	  -webkit-box-direction: reverse;
	  -webkit-box-orient: vertical;
	  -moz-box-direction: reverse;
	  -moz-box-orient: vertical;
	} @else {
	  -webkit-box-direction: normal;
	  -webkit-box-orient: horizontal;
	  -moz-box-direction: normal;
	  -moz-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
	// No Webkit/FF Box fallback.
	-webkit-flex-wrap: $value;
	@if $value == nowrap {
	  -ms-flex-wrap: none;
	} @else {
	  -ms-flex-wrap: $value;
	}
	flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
	// No Webkit/FF Box fallback.
	-webkit-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values;
}

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
	  -webkit-box-pack: start;
	  -moz-box-pack: start;
	  -ms-flex-pack: start;
	} @else if $value == flex-end {
	  -webkit-box-pack: end;
	  -moz-box-pack: end;
	  -ms-flex-pack: end;
	} @else if $value == space-between {
	  -webkit-box-pack: justify;
	  -moz-box-pack: justify;
	  -ms-flex-pack: justify;
	} @else if $value == space-around {
	  -ms-flex-pack: distribute;
	} @else {
	  -webkit-box-pack: $value;
	  -moz-box-pack: $value;
	  -ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	justify-content: $value;
}

@mixin align-items($value: stretch) {
	@if $value == flex-start {
	  -webkit-box-align: start;
	  -moz-box-align: start;
	  -ms-flex-align: start;
	} @else if $value == flex-end {
	  -webkit-box-align: end;
	  -moz-box-align: end;
	  -ms-flex-align: end;
	} @else {
	  -webkit-box-align: $value;
	  -moz-box-align: $value;
	  -ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	align-items: $value;
}

@mixin align-self($value: auto) {
	// No Webkit Box Fallback.
	-webkit-align-self: $value;
	@if $value == flex-start {
	  -ms-flex-item-align: start;
	} @else if $value == flex-end {
	  -ms-flex-item-align: end;
	} @else {
	  -ms-flex-item-align: $value;
	}
	align-self: $value;
}

@mixin align-content($value: stretch) {
	// No Webkit Box Fallback.
	-webkit-align-content: $value;
	@if $value == flex-start {
	  -ms-flex-line-pack: start;
	} @else if $value == flex-end {
	  -ms-flex-line-pack: end;
	} @else {
	  -ms-flex-line-pack: $value;
	}
	align-content: $value;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

@mixin flex-spc-btwn {
	flex-direction: row;
	justify-content: space-between;
}