/* 
==============================================
CONTENT:	My Portfolio
AUTHOR:		Ashley Pollard
==============================================

TABLE OF CONTENTS:

0. Reset
1. Main Layout
2. Content

==============================================
*/
/* 0. Reset ================================= */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Lato|Manjari&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

@-webkit-keyframes dtu-gradient {
	0% {
		background-position: 0% 90%;
	}
	50% {
		background-position: 100% 11%;
	}
	100% {
		background-position: 0% 90%;
	}
}

@-moz-keyframes dtu-gradient {
	0% {
		background-position: 0% 90%;
	}
	50% {
		background-position: 100% 11%;
	}
	100% {
		background-position: 0% 90%;
	}
}

@keyframes dtu-gradient {
	0% {
		background-position: 0% 90%;
	}
	50% {
		background-position: 100% 11%;
	}
	100% {
		background-position: 0% 90%;
	}
}

@-webkit-keyframes dtu-gradient {
	0% {
		background-position: 0% 90%;
	}
	50% {
		background-position: 100% 11%;
	}
	100% {
		background-position: 0% 90%;
	}
}

@-moz-keyframes dtu-gradient {
	0% {
		background-position: 0% 90%;
	}
	50% {
		background-position: 100% 11%;
	}
	100% {
		background-position: 0% 90%;
	}
}

@keyframes dtu-gradient {
	0% {
		background-position: 0% 90%;
	}
	50% {
		background-position: 100% 11%;
	}
	100% {
		background-position: 0% 90%;
	}
}

#particles-js {
	position: absolute;
	width: 100%;
	height: 100vh;
}

/* ---- stats.js ---- */
.count-particles {
	background: gold;
	position: absolute;
	top: 48px;
	left: 0;
	width: 80px;
	color: #b3e9fe;
	font-size: 0.8em;
	text-align: left;
	text-indent: 4px;
	line-height: 14px;
	padding-bottom: 2px;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: bold;
}

.js-count-particles {
	font-size: 1.1em;
}

#stats, .count-particles {
	-webkit-user-select: none;
	margin-top: 5px;
	margin-left: 5px;
}

#stats {
	border-radius: 3px 3px 0 0;
	overflow: hidden;
}

.count-particles {
	border-radius: 0 0 3px 3px;
}

.wrapper {
	width: 100%;
	height: 100vh;
}

.wrapper svg {
	overflow: visible;
	width: 100%;
	height: 100%;
	transform: rotate(180deg);
}

.wrapper .curve {
	fill: transparent;
	stroke-width: 20;
}

.wrapper text {
	fill: #FF9800;
	font-size: 30px;
	transform: rotate(180deg) translate(-100%, -75%);
}

.wrapper .morph-frame {
	display: none;
}

.wrapper #curve1_textpath tspan {
	display: inline-block;
	animation: leSnake 1.5s ease-in-out;
	animation-iteration-count: infinite;
}

@keyframes leSnake {
	from, to {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(30px);
	}
}

@font-face {
	font-family: "Caviar Dreams";
	src: url(../../dist/fonts/Caviar-Dreams/CaviarDreams.ttf);
}

/* 1. Main Layout ================================= */
* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	user-select: none;
}

body {
	width: 100%;
	min-height: 100vh;
	font-family: "Manjari", sans-serif;
	color: #0f0f11;
	background: #fffdf7;
	font-size: 18px;
	cursor: url(../../dist/img/cursor.png), auto;
}

body h1, body h2, body h3 {
	font-family: "Lato", sans-serif;
	line-height: 1.3;
	text-align: center;
}

body h1 span, body h2 span, body h3 span, body p span {
	font-weight: bold;
}

body h1 {
	font-size: 24px;
}

@media only screen and (min-width: 1024px) {
	body h1 {
		font-size: 26px;
	}
}

body h2 {
	font-size: 24px;
}

body p {
	font-size: 19px;
}

#app {
	width: 100%;
	min-height: 100vh;
	position: relative;
}

#app .content, #app .hero-content, #app .scroll-prompt, #app .about-content, #app .work-content {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-direction: normal;
	-webkit-box-orient: vertical;
	-moz-box-direction: normal;
	-moz-box-orient: vertical;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

#app header, #app .hero-content, #app .about-content article {
	padding: 1em;
}

#app .work-content {
	max-width: 768px;
}

@media only screen and (min-width: 1024px) {
	#app .work-content {
		max-width: 1024px;
	}
}

#app .about-content, #app .work-content, #app .work-content h2 {
	margin: 0 0 30px 0;
}

@media only screen and (min-width: 1024px) {
	#app .about-content, #app .work-content, #app .work-content h2 {
		margin: 0 0 75px 0;
	}
}

#app header {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	width: 100%;
}

#app header .logo {
	margin-left: auto;
	background-position: center;
	background-size: cover;
	width: 65px;
	height: 68px;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-align: end;
	-moz-box-align: end;
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	align-items: flex-end;
}

#app .content {
	width: 100%;
}

#app .content .hero-content {
	position: relative;
	width: 100%;
	color: #0f0f11;
	text-align: center;
}

@media only screen and (min-width: 1024px) {
	#app .content .hero-content {
		height: 90vh;
	}
}

#app .content .hero-content .ash-icon {
	background-position: center;
	background-size: cover;
	width: 284px;
	min-height: 228px;
	margin: 0 0 30px 0;
}

#app .content .hero-content .blockquote-carousel {
	font-family: "Caviar Dreams";
	font-size: 50px;
	text-transform: uppercase;
	height: 50px;
	overflow: hidden;
	margin: 0 0 30px 0;
}

#app .content .hero-content .blockquote-carousel div {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-direction: normal;
	-webkit-box-orient: vertical;
	-moz-box-direction: normal;
	-moz-box-orient: vertical;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

#app .content .hero-content .blockquote-carousel div span {
	margin: 0 0 50px 0;
}

#app .content .hero-content h1 {
	margin: 0 0 30px 0;
}

#app .content .hero-content .scroll-prompt {
	margin: auto;
}

#app .content .hero-content .scroll-prompt p {
	margin: 0 0 10px 0;
	font-size: 19px;
}

#app .content .hero-content .scroll-prompt .scroll-arrow {
	background-repeat: no-repeat;
	width: 35px;
	height: 35px;
	cursor: pointer;
}

#app .content .hero-content ul {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	max-width: 246px;
}

@media only screen and (min-width: 768px) {
	#app .content .hero-content ul {
		max-width: 280px;
	}
}

#app .content .hero-content ul li {
	margin: 7px 7px 20px 7px;
}

#app .content .hero-content ul li svg {
	width: 47px;
}

@media only screen and (min-width: 768px) {
	#app .content .hero-content ul li svg {
		width: 55px;
	}
}

#app .content .hero-content ul li svg path, #app .content .hero-content ul li svg circle {
	fill: #fffdf7;
}

#app .content .about-content {
	width: 100%;
	padding: 2em 1em;
	background: #35b0ab;
	text-align: center;
}

@media only screen and (min-width: 1024px) {
	#app .content .about-content {
		height: 85vh;
		-webkit-flex-flow: row;
		-ms-flex-flow: row;
		flex-flow: row;
		-webkit-box-pack: center;
		-moz-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
	}
}

#app .content .about-content .about-icons svg {
	height: 60px;
}

#app .content .about-content .about-icons svg path, #app .content .about-content .about-icons svg circle {
	fill: #35b0ab;
}

#app .content .about-content .about-icons, #app .content .about-content h2, #app .content .about-content h3, #app .content .about-content .article-head {
	margin: 0 0 30px 0;
}

#app .content .about-content h2, #app .content .about-content h3 {
	color: #634099;
}

#app .content .about-content p {
	line-height: 1.3;
}

#app .content .about-content .toolbox ul li {
	margin: 0 0 10px 0;
}

#app .content .about-content article {
	width: 100%;
	max-width: 768px;
	background: #fffdf7;
	-webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#app .content .about-content article:nth-of-type(1) {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

#app .content .about-content article:nth-of-type(3) {
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

@media only screen and (min-width: 1024px) {
	#app .content .about-content article {
		width: 33%;
		max-width: 340px;
		height: 100%;
		border-radius: 5px;
		border-bottom: none;
		margin: 0 0.5px;
	}
}

#app .content .work-content {
	width: 90%;
}

@media only screen and (min-width: 768px) {
	#app .content .work-content {
		width: 96%;
		display: -webkit-inline-box;
		display: -moz-inline-box;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-flex-flow: row wrap;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		-webkit-box-pack: center;
		-moz-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
	}
}

#app .content .work-content h2 {
	width: 100%;
}

#app .content .work-content article {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-direction: normal;
	-webkit-box-orient: vertical;
	-moz-box-direction: normal;
	-moz-box-orient: vertical;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 330px;
	margin: 0 0 1em 0;
	text-align: center;
	border-radius: 10px;
	background-blend-mode: multiply;
	background-color: rgba(0, 0, 0, 0.5);
	overflow: hidden;
	transition: 0.6s ease-in;
}

@media only screen and (min-width: 768px) {
	#app .content .work-content article {
		width: 48.7%;
		height: 230px;
		margin: 0 0.25em 0.5em 0.25em;
	}
}

@media only screen and (min-width: 768px) and (min-width: 1024px) {
	#app .content .work-content article {
		width: 32.3%;
	}
}

#app .content .work-content article h3 {
	position: absolute;
	font-family: "Caviar Dreams";
	color: #fffdf7;
	font-size: 28px;
	padding: 1em;
}

#app .content .work-content article .description {
	line-height: 1.3;
	font-size: 18px;
	padding: 1em;
	color: #fffdf7;
}

@media only screen and (min-width: 768px) {
	#app .content .work-content article .description {
		padding-bottom: 0;
	}
}

#app .content .work-content article .description, #app .content .work-content article a {
	opacity: 0;
	visibility: hidden;
}

@media only screen and (min-width: 768px) {
	#app .content .work-content article a {
		margin-top: auto;
		margin-bottom: 1em;
	}
}

#app .content .work-content article img {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	object-fit: cover;
}

#app .content .work-content article:hover {
	background-color: rgba(0, 0, 0, 0.75);
}

#app .content .work-content article:hover img {
	width: 110%;
	height: 110%;
}

#app .content .work-content article:hover h3 {
	opacity: 0;
}

#app .content .work-content article:hover .description, #app .content .work-content article:hover a {
	opacity: 1;
	visibility: visible;
}

#app .content .work-content img, #app .content .work-content h3, #app .content .work-content .description, #app .content .work-content a, #app .content .more-content img, #app .content .more-content h3, #app .content .more-content .description, #app .content .more-content a {
	transition: 0.33s ease-in;
}

#app .content .work-content a, #app .content .more-content a {
	font-family: "Lato", sans-serif;
	font-size: 20px;
	text-decoration: none;
	text-transform: uppercase;
	color: #fffdf7;
	border: 2px solid #35b0ab;
	padding: 20px 1.7em;
	border-radius: 40px;
	z-index: 1;
	line-height: 1;
}

#app .content .work-content a:hover, #app .content .more-content a:hover {
	background: #35b0ab;
	color: #fffdf7;
}

#app .content .more-content {
	width: 100%;
	text-align: center;
	padding: 0 1em;
}

#app .content .more-content p {
	margin: 0 0 30px 0;
}

#app .content .more-content a {
	display: inline-block;
	color: #0f0f11;
}

#app footer {
	margin: 2em 0;
	text-align: center;
}

#app footer ul, #app footer p {
	margin: 0 0 20px 0;
}

#app footer p {
	font-size: 15px;
}

#app footer ul {
	width: 100%;
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}

#app footer ul li {
	margin: 0 1em 0 0;
}

#app footer ul li:last-child {
	margin: 0;
}

#app footer ul li svg {
	width: 50px;
	padding: 0.3em;
	border: 2px solid #35b0ab;
	border-radius: 100%;
	transition: 0.33s ease-in;
}

#app footer ul li svg:hover {
	background: #35b0ab;
}

#app footer ul li svg:hover path, #app footer ul li svg:hover circle {
	fill: #fffdf7;
}

#app footer .copyright {
	font-family: "Caviar Dreams";
	color: #634099;
	font-weight: bold;
}

#app footer .copyright span {
	font-size: 22px;
}

#app footer .footer-blurb {
	margin: 0 0 30px 0;
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

#app footer .footer-blurb img {
	width: 45px;
}

#app footer .footer-blurb span {
	color: #41b883;
	font-weight: bold;
	align-self: flex-end;
	margin-left: -10px;
}

body::-webkit-scrollbar {
	width: 6px;
}

body::-webkit-scrollbar-track {
	background: linear-gradient(43deg, #35b0ab, #634099);
}

body::-webkit-scrollbar-thumb {
	width: 0.3em;
	background: #b3e9fe;
	border-radius: 6px;
}
