@-webkit-keyframes dtu-gradient {
    0%{background-position:0% 90%}
    50%{background-position:100% 11%}
    100%{background-position:0% 90%}
}
@-moz-keyframes dtu-gradient {
    0%{background-position:0% 90%}
    50%{background-position:100% 11%}
    100%{background-position:0% 90%}
}
@keyframes dtu-gradient {
    0%{background-position:0% 90%}
    50%{background-position:100% 11%}
    100%{background-position:0% 90%}
}

// /* ---- particles.js container ---- */
#particles-js {
	position: absolute;
	width: 100%;
	height: 100vh;
	// background-color: #232741;
	// background-image: url("http://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/NASA_logo.svg/1237px-NASA_logo.svg.png");
	// background-repeat: no-repeat;
	// background-size: 20%;
	// background-position: 50% 50%;
} /* ---- stats.js ---- */

.count-particles {
	background: gold;
	position: absolute;
	top: 48px;
	left: 0;
	width: 80px;
	color: $dtu-blue;
	font-size: 0.8em;
	text-align: left;
	text-indent: 4px;
	line-height: 14px;
	padding-bottom: 2px;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: bold;
}

.js-count-particles {
	font-size: 1.1em;
}
  
#stats, .count-particles {
	-webkit-user-select: none;
	margin-top: 5px;
	margin-left: 5px;
}

#stats {
	border-radius: 3px 3px 0 0;
	overflow: hidden;
}

.count-particles {
	border-radius: 0 0 3px 3px;
}