/* 
==============================================
CONTENT:	My Portfolio
AUTHOR:		Ashley Pollard
==============================================

TABLE OF CONTENTS:

0. Reset
1. Main Layout
2. Content

==============================================
*/

/* 0. Reset ================================= */
@import 'reset';

@import 'variables';

@import 'fontfaces';

@import 'animations';

@import 'internal';

// @import 'hamburgers';

@font-face {
	font-family: "Caviar Dreams";
	src: url(../../dist/fonts/Caviar-Dreams/CaviarDreams.ttf);
}

@import url('https://fonts.googleapis.com/css?family=Lato|Manjari&display=swap');
$body-font: 'Manjari', sans-serif;
$heading-font: 'Lato', sans-serif;
$special-font: "Caviar Dreams";

/* 1. Main Layout ================================= */

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
	user-select: none;
}

body {
	width: 100%;
	min-height: 100vh;
	font-family: $body-font;
	color: $dtu-black;
	background: $dtu-white;
	font-size: 18px;
	cursor: url(../../dist/img/cursor.png), auto;

	h1, h2, h3 {
		font-family: $heading-font;
		line-height: 1.3;
		text-align: center;
	}

	h1, h2, h3, p {
		
		span {
			font-weight: bold;
		}
	}

	h1 {
		font-size: 24px;

		@include bp-desktop {
			font-size: 26px;
		}
	}

	h2 {
		font-size: 24px;
	}

	p {
		font-size: 19px;
	}
}

// /* 2. Content ================================= */


#app {
	width: 100%;
	min-height: 100vh;
	position: relative;

	.content, .hero-content, .scroll-prompt, .about-content, .work-content {
		@include flex-support;
		@include flex-direction(column);
		@include align-items(center);
	}

	header, .hero-content, .about-content article {
		padding: 1em;
	}

	.work-content {
		max-width: 768px;

		@include bp-desktop {
			max-width: 1024px;
		}
	}

	.about-content, .work-content, .work-content h2  {
		margin: 0 0 30px 0;

		@include bp-desktop {
			margin: 0 0 75px 0;
		}
	}

	header { //look into widows
	@include flex-support;
	width: 100%;

		// a {
			// margin-left: auto;
			// text-decoration: none;
			// z-index: 1;

			.logo {
				margin-left: auto;
				background-position: center;
				background-size: cover;
				width: 65px;
				height: 68px;
				@include flex-support;
				@include align-items(flex-end);
			}
		// }
	}

	.content {
		width: 100%;

		.hero-content {
			position: relative;
			width: 100%;
			color: $dtu-black;
			text-align: center;

			@include bp-desktop {
				height: 90vh;
			}

			// background: linear-gradient(43deg, #35b0ab, #634099);
			// background-size: 400% 400%;

			// -webkit-animation: dtu-gradient 15s ease infinite;
			// -moz-animation: dtu-gradient 15s ease infinite;
			// animation: dtu-gradient 15s ease infinite;

			.ash-icon {
				background-position: center;
				background-size: cover;
				width: 284px;
				min-height: 228px;
				margin: 0 0 30px 0;
			}

			.blockquote-carousel {
				font-family: $special-font;
				font-size: 50px;
				text-transform: uppercase;
				height: 50px;
				overflow: hidden;
				margin: 0 0 30px 0;

				div {
					@include flex-support;
					@include flex-direction(column);

					span {
						margin: 0 0 50px 0;
					}
				}
			}

			h1 {
				margin: 0 0 30px 0;
			}

			.scroll-prompt {
					margin: auto;

				p {
					margin: 0 0 10px 0;
					font-size: 19px;
				}
	
				.scroll-arrow {
					background-repeat: no-repeat;
					width: 35px;
					height: 35px;
					cursor: pointer;
				}
			}

			ul {
				@include inline-flex;
				@include justify-content(center);
				max-width: 246px;
				
				@include bp-tablet {
					max-width: 280px;
				}
				
				li {
					margin: 7px 7px 20px 7px;

					svg {
						width: 47px;

						@include bp-tablet {
							width: 55px;
						}

						path, circle {
						fill: $dtu-white;
						}
					}
				}
			}

			// // 		li {
// // 			height: 33px;
// // 			margin-bottom: 30px;

// // 			&:after {
// // 				content: '';
// // 				display: inline-block;
// // 				width: 100%;
// // 				height: 1px;
// // 				background: gold;
// // 				transition: 0.3s ease-out;
// // 			}

// // 			&:hover {

// // 				&:after {
// // 					background: #b3e9fe;
// // 				}
// // 			}

// // 			a {
// // 				color: #fff;
// // 				font-weight: bold;
// // 				text-decoration: none;
// // 			}
		}

		.about-content {
			width: 100%;
			padding: 2em 1em;
			background: $dtu-green;
			text-align: center;

			@include bp-desktop {
				height: 85vh;
				@include flex-flow(row);
				@include justify-content(center);
			}

			.about-icons {

				svg {
					height: 60px;

					path, circle {
						fill: $dtu-green;
					}
				}
			}

			.about-icons, h2, h3, .article-head {
				margin: 0 0 30px 0;
			}

			h2, h3 {
				color: $dtu-purple;
			}

			p {
				line-height: 1.3;
			}

			.toolbox {

				ul { 
					li {
						margin: 0 0 10px 0;
					}
				}
			}

			article {
				width: 100%;
				max-width: 768px;
				background: $dtu-white;

				-webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);
				-moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);
				box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);
				border-bottom: 1px solid rgba(0,0,0,0.1);

				&:nth-of-type(1) {
					border-top-left-radius: 5px;
    			border-top-right-radius: 5px;
				}

				&:nth-of-type(3) {
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
				}

				@include bp-desktop {
					width: 33%;
					max-width: 340px;
					height: 100%;
					border-radius: 5px;
					border-bottom: none;
					margin: 0 0.5px;
				}
			}
		}

		.work-content {
			width: 90%;

			@include bp-tablet {
				width: 96%;
				@include inline-flex;
				@include flex-flow(row wrap);
				@include justify-content(center);
			}

			h2 {
				width: 100%;
			}

			article {
				@include flex-support;
				@include flex-direction(column);
				@include align-items(center);
				@include justify-content(center);
				position: relative;
				width: 100%;
				height: 330px;
				margin: 0 0 1em 0;
				text-align: center;
				border-radius: 10px;
				background-blend-mode: multiply;
				background-color: rgba(0,0,0,0.5);
				overflow: hidden;
				transition: 0.6s ease-in;

				// &:last-child {
				// 	margin: 0;
				// }

				@include bp-tablet{
					width: 48.7%;
					height: 230px;
					margin: 0 0.25em 0.5em 0.25em;

					// &:nth-of-type(odd) {
					// 	margin: 0 1em 1em 0;
					// }

					// &:last-child {
					// 	margin: 0 0 1em 0;
					// }

					@include bp-desktop {
						width: 32.3%;
					}
				}

				// @include bp-max {
				// 	width: 32.2%;
				// 	margin: 0 1em 1em 0;

				// 	&:nth-of-type(3n) {
				// 		margin: 0 0 1em 0;
				// 	}
				// }
				
				h3 {
					position: absolute;
					font-family: $special-font;
					color: $dtu-white;
					font-size: 28px;
					padding: 1em;
				}

				.description {
					line-height: 1.3;
					font-size: 18px;
					padding: 1em;
					color: $dtu-white;
					
					@include bp-tablet {
						padding-bottom: 0;
					}
				}

				.description, a {
					opacity: 0;
					visibility: hidden;
				}

				a {

					@include bp-tablet {
						margin-top: auto;
						margin-bottom: 1em;
					}
				}

				img {
					width: 100%;
					height: 100%;
					position: absolute;
					z-index: -1;
					object-fit: cover;
				}

				&:hover {
					background-color: rgba(0,0,0,0.75);

					img {
						width: 110%;
						height: 110%;
					}

					h3 {
						opacity: 0;
					}
	
					.description, a {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		.work-content, .more-content {

			img, h3, .description, a {
				transition: 0.33s ease-in;
			}

			a {
				font-family: $heading-font;
				font-size: 20px;
				text-decoration: none;
				text-transform: uppercase;
				color: $dtu-white;
				border: 2px solid $dtu-green;
				padding: 20px 1.7em;
				border-radius: 40px;
				z-index: 1;
				line-height: 1;

				&:hover {
					background: $dtu-green;
					color: $dtu-white;
				}
			}
		}

		.more-content {
			width: 100%;
			text-align: center;
			padding: 0 1em;
			
			p {
				margin: 0 0 30px 0;
			}

			a {
				display: inline-block;
				color: $dtu-black;
			}
		}
	}

	footer {
		margin: 2em 0;
		text-align: center;

		ul, p {
			margin: 0 0 20px 0;
		}

		p {
			font-size: 15px;
		}

		ul {
			width: 100%;
			@include inline-flex;
			@include justify-content(center);

			li {
				margin: 0 1em 0 0;

				&:last-child {
					margin: 0;
				}
				
				svg {
					width: 50px;
					padding: 0.3em;
					border: 2px solid $dtu-green;
					border-radius: 100%;
					transition: 0.33s ease-in;

					&:hover {
						background: $dtu-green;

						path, circle {
							fill: $dtu-white;
						}
					}
				}
			}
		}

		.copyright {
			font-family: $special-font;
			color: $dtu-purple;
			font-weight: bold;

			span {
				font-size: 22px;
			}
		}

		.footer-blurb {
			margin: 0 0 30px 0;
			@include inline-flex;
			@include align-items(center);

			img {
				width: 45px;
			}

			span {
				color: #41b883;
				font-weight: bold;
				align-self: flex-end;
				margin-left: -10px;
			}
		}
	}
}

body::-webkit-scrollbar {
	width: 6px;
}

body::-webkit-scrollbar-track {
	background: linear-gradient(43deg, #35b0ab, #634099);
}

body::-webkit-scrollbar-thumb {
	width: 0.3em;
	background: $dtu-blue;
	border-radius: 6px;
}


